import React, { useRef, useEffect, useState } from 'react';

const LazyWrapper = ({ component: Component}) => {
    const reference = useRef(null);
    const [isAboveFold, setIsAboveFold] = useState(false);

    useEffect(() => {
        const handleIntersect = (entries, observer) => {
            if (entries[0].isIntersecting) {
                setIsAboveFold(true);
                observer.unobserve(entries[0].target);
                observer.disconnect();
            }
        };
        // Create the observer, passing in the callback
        const observer = new IntersectionObserver(handleIntersect);

        // If we have a ref value, start observing it
        if (reference && reference.current) {
            observer.observe(reference.current);
        }
    }, [reference])

    return (
        <div ref={reference}>
            {isAboveFold && <Component />}
        </div>
    )
}

export default LazyWrapper;