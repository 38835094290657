import React from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import { StaticQuery, graphql } from 'gatsby'
import styled, { keyframes } from 'styled-components'
import AnimationContainer from 'components/animation-container'
import AnimatedHeading from 'components/animated-heading'

class ServicesOne extends React.Component {

  constructor(props) {
    super(props);
    console.log('Props ->', props);
  }

  shouldComponentUpdate() {
    return false
  }



  showVideos() {
    const AnimatedShadow = keyframes`
            0%   {box-shadow: 0 28px 60px rgba(4, 229, 229, .5);}
            10%  {box-shadow: 0 28px 60px rgba(243, 112, 85, .5);}
            20%  {box-shadow: 0 28px 60px rgba(239, 78, 123, .5);}
            30%  {box-shadow: 0 28px 60px rgba(161, 102, 171, .5);}
            40% {box-shadow: 0 28px 60px rgba(80, 115, 184, .5);}
            50% {box-shadow: 0 28px 60px rgba(4, 229, 229, .5);}
            60% {box-shadow: 0 28px 60px rgba(7, 179, 155, .5);}
            70% {box-shadow: 0 28px 60px rgba(111, 186, 130, .5);}
            80% {box-shadow: 0 28px 60px rgba(80, 115, 184, .5);}
            90% {box-shadow: 0 28px 60px rgba(16, 152, 173, .5);}
            100% {box-shadow: 0 28px 60px rgba(243, 112, 85, .5);}
        `

    const ServiceElement = styled.div`
            margin-bottom: 16px;
            text-align: center;
            padding: 16px;
            border-radius: 20px;
            transition: .2s;
            &:hover {
                background-color: #00143d;
                animation: ${AnimatedShadow} 10s infinite alternate;
                transform: translateY(-10px);
            }
            iframe {
                width: 350px;
                height: 200px;
                @media (max-width: 767px) {
                  height: 100%;
                  width: 100%;
              }
            }
        `

    const StyledCol = styled(Col)`
            padding: 0px;
    `

    const ServiceHeading = styled.h4`
            font-size: 30px;
            font-weight: 500;
            font-family: Teko;
            color: #fff;
        `
    const ServiceSeparator = styled.div`
            height: 5px;
            width: 50px;
            background-color: #04e5e5;
            margin-bottom: 10px;
            margin: auto;
         `
    const mdVideos = this.props.items.edges;
    console.log("🚀 ~ file: VideoLinks.js:137 ~ ServicesOne ~ showVideos ~ mdVideos:", mdVideos)
    return mdVideos.map((video) => {
      const { content: { frontmatter: { my_work_id: id, my_work_src: src, my_work_title: title } } } = video;
      return (
        <StyledCol xs={12} md={4} key={id}>
          <AnimationContainer animation="fadeInLeft" delay={100}>
            <ServiceElement>
              <iframe src={src} title={title} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen loading="lazy"></iframe>
              <ServiceHeading>
                {title}
              </ServiceHeading>
              <ServiceSeparator />
            </ServiceElement>
          </AnimationContainer>
        </StyledCol>
      )
    })
  }

  render() {
    const Section = styled.section`
            position: relative;
            overflow: hidden;
            background-color: #111;
            background-size: cover;
            .heading {
                width: 100%;
            }
          }
        `

    const ServiceContainer = styled.div`
            background-color: #00143d;
            padding: 100px 0;
        `

    const StyledRow = styled(Row)`
          display: flex;
          justify-content: center;
    `

    return (
      <Section id="music">
        <ServiceContainer>
          <Container>
            <AnimatedHeading text="My Works" />
            <StyledRow>
              {this.showVideos()}
            </StyledRow>
          </Container>
        </ServiceContainer>
      </Section>
    )
  }

}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        items: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "//(content/data/my-work)//"}}, sort: {fields: [frontmatter___my_work_id], order: ASC}, limit: 50) {
          edges {
            content: node {
              frontmatter {
                my_work_id
                my_work_src
                my_work_title
              }
            }
          }
        }
      }      
      `}
    render={({
      items }) => <ServicesOne
        items={items}
        {...props} />}
  />
)