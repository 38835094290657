import React from 'react'
import styled, { keyframes } from 'styled-components'
import Progress from 'components/progress'
import Timeline from 'sections/about/parts/Timeline'

class TabsPart extends React.Component {
    constructor(props) {
        super(props)
        console.log("🚀 ~ file: TabsPart.js:13 ~ TabsPart ~ constructor ~ props:", props)
        this.state = {
            tab: "experience",
            progressBarWidth: (typeof window !== 'undefined' && window.innerWidth < 500) ? 60 : 33
        }
    }

    render() {
        const TabContainer = styled.div`
            min-height: 400px;
            margin-top: 20px;
            @media (max-width:767px) {
                margin-top: 50px;
                padding: 0 20px;
            }
        `
        const TabSelectors = styled.div`
            display: flex;
        `

        const ColorAnimation = keyframes`
            0%  {border-color: #04e5e5;}
            10% {border-color: #f37055;}
            20% {border-color: #ef4e7b;}
            30% {border-color: #a166ab;}
            40% {border-color: #5073b8;}
            50% {border-color: #04e5e5;}
            60% {border-color: #07b39b;}
            70% {border-color: #6fba82;}
            80% {border-color: #5073b8;}
            90% {border-color: #1098ad;}
            100% {border-color: #f37055;}
        `
        const TabSelector = styled.button`
            color: #fff;
            font-size: 20px;
            font-weight: bold;
            background-color: transparent;
            border: none;
            margin: 0 10px 0 0;
            border-bottom: 2px solid #fff;
            transition: .5s;
            &:hover, &.active {
                animation: ${ColorAnimation} 10s infinite alternate;
            }
            &:focus {
                outline: none;
            }
            @media (max-width: 767px) {
                font-size: 18px;
            }
        `

        const Tabs = styled.div`
            margin-top: 20px;
        `

        const Fade = keyframes`
            0% {
                opacity: 0;
            }
            100% {
                opacity: 1;
            }
        `

        const Tab = styled.div`
            display: none;
            animation: ${Fade} 1s forwards;
            width: 100%;
        `

        const renderSkills = () => {
            const { tabsContent: { skillsTab = [] } } = this.props;
            return skillsTab.map((skill, index) => (
                <Progress width={this.state.progressBarWidth} text={skill} key={`${skill}-${index}`} />
            ))
        }
        const renderExperience = () => {
            const { tabsContent: { experienceTab = [] } } = this.props;
            return experienceTab.map(({ noOfProjects, typeOfProject }, index) => (
                <Progress width={100} value={noOfProjects} text={typeOfProject} key={`${index}-${noOfProjects}-${typeOfProject}`} />
            ))
        }
        const getEducation = () => {
            const { tabsContent: { educationTab = [] } } = this.props;
            const mappedEducation = {};
            educationTab.map(({ college, titleOfDegree, years }, index) => {
                mappedEducation[index] = {
                    title: titleOfDegree,
                    institution: college,
                    description: "",
                    year: years
                }
            })
            return mappedEducation;
        }
        return (
            <TabContainer>
                <TabSelectors>
                    <TabSelector className={this.state.tab === "experience" ? "active" : ""} onClick={() => this.setState({ tab: "experience" })}>
                        Experience
                    </TabSelector>
                    <TabSelector className={this.state.tab === "skills" ? "active" : ""} onClick={() => this.setState({ tab: "skills" })}>
                        Skills
                    </TabSelector>
                    <TabSelector className={this.state.tab === "education" ? "active" : ""} onClick={() => this.setState({ tab: "education" })}>
                        Education
                    </TabSelector>
                </TabSelectors>
                <Tabs>
                    <Tab style={{
                        display: this.state.tab === "skills" ? "block" : "none"
                    }}>
                        {renderSkills()}
                    </Tab>
                </Tabs>
                <Tabs>
                    <Tab style={{
                        display: this.state.tab === "experience" ? "block" : "none"
                    }}>
                        {renderExperience()}
                    </Tab>
                    <Tab style={{
                        display: this.state.tab === "education" ? "block" : "none"
                    }}>
                        <Timeline data={getEducation()}
                        />
                    </Tab>
                </Tabs>
            </TabContainer>
        )
    }
}

export default TabsPart