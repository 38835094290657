import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Col, Container } from 'react-bootstrap'
import styled from 'styled-components'
import PortfolioItem from 'sections/portfolio/parts/PortfolioItem.js'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import AnimatedHeading from 'components/animated-heading'
import AnimationContainer from 'components/animation-container'
import PortoflioTabsPart from './parts/PortoflioTabsPart'

class PortfolioThree extends React.Component {

      constructor(props) {
        super(props)
        this.state = {
            height: 0,
            width: 0,
            curActiveTab: 1,
        }
    }

    updateDimensions = () => {
        if (this.state.height !== window.innerHeight) {
            this.setState({height: window.innerHeight})
        }
        if (this.state.width !== window.innerWidth) {
            this.setState({width: window.innerWidth})
        }
    }

    
    componentDidMount() {
        this.setState({height: window.innerHeight, width: window.innerWidth})
        window.addEventListener('resize', this.updateDimensions)
    }
    
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions)
    }

    onTabChange = (value) => {
      this.setState({
        curActiveTab: value
      })
    }
    
    getMyItems = () => {
      const { items = [] } = this.props;
      return items.filter(item => {
        return item.content.frontmatter.subCategory === this.state.curActiveTab
      })
    }

    render() {

        const Section = styled.section`
          background-color: #010e2a;
          padding: 100px 0;
        `
        const PortfolioContainer = styled.div`
            .slick-slide {
              display: block;
              margin: 0px 0 70px 0px;
            }
            .slick-dots {
              bottom: 0;
              li button:before,.slick-dots li.slick-active button:before {
                color: #04e5e5;
              }
            }
          }
        `


        const settings = {
            dots: !(this.state.width < 500),
            swipe: true,
            infinite: this.getMyItems().length > 3,
            speed: 2000,
            slidesToScroll:  this.state.width < 500 ? 1 : 2,
            arrows: false,
            autoplay: true,
            autoplaySpeed: this.state.width < 500 ? 2000: 2500,
            loop: true,
            slidesToShow: this.state.width < 500 ? 1 : this.state.width > 500 && this.state.width <= 1024 ? 3 : 4,
            lazyLoad: 'ondemand',
            pauseOnDotsHover: true,
            pauseOnHover: true,

        }
        
        return (
            <Section id="portfolio">
                  <Col md={12} style={{padding: 0}}>
                    <Container>
                      <AnimatedHeading text="Recent projects" />
                      <PortoflioTabsPart activeTab={this.state.curActiveTab} onTabChange={this.onTabChange} />
                    </Container>
                    <PortfolioContainer>
                      <AnimationContainer animation="fadeIn">
                        <Slider {...settings}>
                          {this.portfolio(this.getMyItems())}
                        </Slider>
                      </AnimationContainer>
                    </PortfolioContainer>
                </Col>
            </Section>
        )
    }

  portfolio(projects = []) {
      return projects.map((value, index) => {
        return (
          <PortfolioItem 
            key={index}
            index={index} 
            image={value.content.frontmatter.portfolio_image} 
            text={value.content.frontmatter.title} 
            category={value.content.frontmatter.category}
            link={value.content.frontmatter.link}
            type="slider"
          />
        )
      })
    }
}

export default props => (
  <StaticQuery
      query={graphql`
          query {
            items: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "//(content/data/portfolio)//"}}, sort: {fields: [frontmatter___id], order: ASC}, limit: 50) {
              edges {
                content: node {
                  frontmatter {
                    id
                    title
                    category
                    subCategory
                    link
                    portfolio_image
                  }
                }
              }
            }
            }           
          `}
      render={({ items }) => <PortfolioThree items={items.edges} {...props} />}
  />
)