import React from 'react'
import styled from 'styled-components'
import { Container } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { faSoundcloud, faSpotify, faYoutube, faInstagram, faImdb } from '@fortawesome/free-brands-svg-icons'

var scrollToElement = require('scroll-to-element')

class Navbar extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            collapse: false,
            sticky: false,
            sections: this.props.sections ? this.props.sections : ['home', 'bio', 'services', 'portfolio', 'testimonials', 'clients', 'team', 'blog', 'contact']
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll, { passive: true })
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll)
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (window.pageYOffset >= 50 && this.state.sticky) {
            if (this.state.collapse !== nextState.collapse) {
                return true
            }
            return false
        } else {
            return true
        }
    }

    handleScroll = event => {
        if (window.pageYOffset >= 50) {
            this.setState({ sticky: true })
        } else {
            this.setState({ sticky: false })
        }
    }

    collapseNav() {
        console.log(this.state, 'col')
        if (!this.state.collapse) {
            this.setState({ collapse: true })
        } else {
            this.setState({ collapse: false })
        }
    }


    shouldShowLogo = () => {
        if(typeof window !== 'undefined' && window.innerWidth < 500) {
            if(this.state.sticky) {
                return false;
            }
        }
        return true;
    }

    render() {

        const NavbarWrapper = styled.div`
            position: absolute;
            z-index: 1;
            width: 100%;
            padding: 4px 0;
            z-index: 100;
            background-color: #010e2a;
            &.sticky {
                position: fixed;
                background-color: rgba(13,19,31,.8);
                padding: 0 0;
                @media (max-width: 500px) {
                    padding: 0px 0;
                }
            }
        `

        const NavbarContainer = styled(Container)`
            display: flex;
            position: relative;
            @media (max-width: 500px) {
                display: block;
                padding: 0;
            }
            
        `

        const Nav = styled.nav`
            flex: 0 0 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            font-size: 18px;
            @media (max-width: 500px) {
                flex: 0 0 100%;
                max-width: 100%;
                justify-content: center;
                background-color: rgba(13,19,31,.8);
                margin-top: 20px;
                &.hidden_mobile {
                    display: none;
                }
                font-size: 16px;
            }
        `

        const LogoWrapper = styled.div`
            flex: 0 0 20%;
            max-width: 5%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            @media (max-width: 500px) {
                flex: 0 0 100%;
                max-width: 100%;
                justify-content: center;
            }
        `

        const Logo = styled.img`
            height: 70px;
            @media (max-width: 1023px) {
                height: 40px;
            }
        `

        const NavInner = styled.div`
            justify-content: flex-start;
            width: 100%;
            .navbar-nav {
                width: 80%;
                justify-content: space-around;
                @media (max-width: 767px) {
                    width: 100%;
                }
            }
        `

        const Toggler = styled.button`
            color: #fff;
            position: absolute;
            right: 0;
            top: 0;
            z-index: 12;
            @media (min-width: 500px) {
                display: none;
            }
        `
        const SocialIcons = styled.div`
            .social_icon {
                font-size: 15px;
                color: #FFF;
                margin: 0 5px;
                cursor: pointer;
                transition: .5s;
                &:hover {
                    color: #04e5e5;
                }
                @media (max-width: 500px) {
                    font-size: 32px;
                    margin: 8px;
                }
            }
        `

        const SocialIconsContainer = styled.div`
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
        `;

        return (
            <NavbarWrapper className={`header${this.state.sticky === true ? ' sticky' : ''}`}>
                <NavbarContainer>
                    {this.shouldShowLogo() && (<LogoWrapper className="logo">
                        <Logo src="/img/srikant-Logo.jpg" alt="logo" />
                    </LogoWrapper>)}
                    <Toggler
                        onClick={() => this.collapseNav()}
                        className="navbar-toggler navbar-toggler-right"
                    >
                        <FontAwesomeIcon icon={faBars} className="bars" />
                    </Toggler>
                    <Nav style={this.state.sticky && typeof window !== 'undefined' && window.innerWidth < 500 ? { marginTop: 0 }: { marginTop: 20}} className={`navbar navbar-expand-sm ${this.state.collapse === true ? 'expand' : 'hidden_mobile'}`}>
                        <NavInner className={`navbar-collapse collapse ${this.state.collapse === true ? 'show' : ''}`}>
                            <div className="navbar-nav">{this.navItems()}</div>
                            <SocialIconsContainer>
                                <SocialIcons>
                                    <FontAwesomeIcon icon={faSpotify} className="social_icon" onClick={() => window.open('https://open.spotify.com/artist/3CzoBGsEukKWTwHhCQWA1x?si=CEByu1mwSZK-2rQI40QBtA')} />
                                    <FontAwesomeIcon icon={faYoutube} className="social_icon" onClick={() => window.open('https://www.youtube.com/user/srikantk90')} />
                                    <FontAwesomeIcon icon={faSoundcloud} className="social_icon" onClick={() => window.open('https://soundcloud.com/srikantk')} />
                                    <FontAwesomeIcon icon={faInstagram} className="social_icon" onClick={() => window.open('https://www.instagram.com/srikant_krishna/')} />
                                    <FontAwesomeIcon icon={faImdb} className="social_icon" onClick={() => window.open('https://www.imdb.com/name/nm9798541/')} />
                                </SocialIcons>
                            </SocialIconsContainer>
                        </NavInner>
                    </Nav>
                </NavbarContainer>
            </NavbarWrapper>
        )
    }

    navigate(id) {
        if (this.props.scroll) {
            const el = document.getElementById(id)
            scrollToElement(el, {
                offset: 0,
                ease: 'in-out-expo',
                duration: 2000
            })
        } else {
            window.location.href = `./#${id}`;
        }
        if(typeof window !== 'undefined' && window.innerWidth < 500) {
            console.log('Collapsing Navbar')
            this.collapseNav()
        }
    }

    navItems() {
        const NavItem = styled.button`
            background: none;
            border: none;
            color: #fff;
            text-transform: capitalize;
            font-weight: 500;
            margin: 10px 5px;
            transition: .5s;
            &:hover {
                color: #04e5e5;
            }
            &:focus {
                outline: none;
            }
            @media (min-width: 501px) and (max-width: 1023px) {
                font-size: 11px;
                margin: 2px;
            }
        `

        return this.state.sections.map((value, index) => {
            return (
                <NavItem key={index} onClick={() => this.navigate(value)}>
                    {value}
                </NavItem>
            )
        })
    }
}

export default Navbar