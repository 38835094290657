import React, { useEffect, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Row, Col, Container, Popover, OverlayTrigger } from 'react-bootstrap'
import styled, { createGlobalStyle, css, keyframes } from 'styled-components'
import AnimationContainer from 'components/animation-container'
import AnimatedHeading from 'components/animated-heading'

const Section = styled.section`
            position: relative;
            overflow: hidden;
            background-color: #111;
            background-size: cover;
            .heading {
                width: 100%;
            }
          }
        `

const ServiceContainer = styled.div`
            background-color: #00143d;
            padding: 100px 0;
        `
const gradientAnimation = keyframes`
            0% {
                background-position: 15% 0%;
            }
            50% {
                background-position: 85% 100%;
            }
            100% {
                background-position: 15% 0%;
            }
        `

const AnimatedShadow = keyframes`
            0%   {box-shadow: 0 28px 60px rgba(4, 229, 229, .5);}
            10%  {box-shadow: 0 28px 60px rgba(243, 112, 85, .5);}
            20%  {box-shadow: 0 28px 60px rgba(239, 78, 123, .5);}
            30%  {box-shadow: 0 28px 60px rgba(161, 102, 171, .5);}
            40% {box-shadow: 0 28px 60px rgba(80, 115, 184, .5);}
            50% {box-shadow: 0 28px 60px rgba(4, 229, 229, .5);}
            60% {box-shadow: 0 28px 60px rgba(7, 179, 155, .5);}
            70% {box-shadow: 0 28px 60px rgba(111, 186, 130, .5);}
            80% {box-shadow: 0 28px 60px rgba(80, 115, 184, .5);}
            90% {box-shadow: 0 28px 60px rgba(16, 152, 173, .5);}
            100% {box-shadow: 0 28px 60px rgba(243, 112, 85, .5);}
        `
const ServiceElement = styled.div`
            margin-bottom: 20px;
            text-align: center;
            padding: 40px;
            border-radius: 20px;
            transition: .2s;
            &:hover {
                background-color: #00143d;
                animation: ${AnimatedShadow} 10s infinite alternate;
                transform: translateY(-10px);
            }
        `
const ServiceHeading = styled.h4`
            font-size: 30px;
            font-weight: 500;
            font-family: Teko;
            color: #fff;
        `
const ServiceSeparator = styled.div`
            height: 5px;
            width: 50px;
            background-color: #04e5e5;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 10px;
            margin-top: ${props => props.customStyle.marginTop || '0px'};
         `
const ServiceIcon = styled.div`
            width: 120px;
            height: 120px;
            margin: 0 auto 25px auto;
            background-color: #fff;
            border-radius: 200px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: linear-gradient(120deg, #04e5e5, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
            background-size: 300% 300%;
            animation: ${gradientAnimation} 5s ease-in-out infinite;
            img {
                max-height: 70px;
            }
        `

const StyledCol = styled(Col)`
    min-height: 320px
`

const StyledPopOver = styled.div`
    background-color: #fff;
    background: linear-gradient(120deg, #04e5e5, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
    background-size: 300% 300%;
    animation: ${gradientAnimation} 5s ease-in-out infinite;
`

const gradientAnimationBorder = keyframes`
            0% {
                background-position: 15% 0%;
            }
            50% {
                background-position: 85% 100%;
            }
            100% {
                background-position: 15% 0%;
            }
        `

const Gradient = styled.div`
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            background: linear-gradient(120deg, #04e5e5, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
            background-size: 300% 300%;
            clip-path: polygon(0% 100%, 10px 100%, 10px 10px, calc(100% - 10px) 10px, calc(100% - 10px) calc(100% - 10px), 10px calc(100% - 10px), 10px 100%, 100% 100%, 100% 0%, 0% 0%);
            animation: ${gradientAnimationBorder} 5s ease-in-out infinite;
        
        `


const TestimonialBox = styled.div`
            display: flex;
            flex-direction: column;
            position: relative;
        `

const GlobalStyles = createGlobalStyle`
          .popover-body {
            white-space: pre-line;
            animation: ${gradientAnimation} 5s ease-in-out infinite;
            font-size: 14px;
            font-weight: normal;
            color: white;
          }
          #popover-basic {
            background-color: #fff;
            background: linear-gradient(120deg, #04e5e5, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
            background-size: 300% 300%;
            animation: ${gradientAnimation} 5s ease-in-out infinite;
          }
    `

const Services = ({ }) => {

    const props = useStaticQuery(graphql`
    query {
        background: file(relativePath: {eq: "bg2.jpg"}) {
          childImageSharp {
            fluid(maxWidth: 4000, quality: 100) {
              src
            }
          }
        }
        web: file(relativePath: {eq: "icons/orchestrate.png"}) {
          childImageSharp {
            fluid(maxWidth: 500) {
              src
            }
          }
        }
        mobile: file(relativePath: {eq: "icons/compose.png"}) {
          childImageSharp {
            fluid(maxWidth: 500) {
              src
            }
          }
        }
        seo: file(relativePath: {eq: "icons/sound.png"}) {
          childImageSharp {
            fluid(maxWidth: 500) {
              src
            }
          }
        }
        email: file(relativePath: {eq: "icons/score_prep.png"}) {
          childImageSharp {
            fluid(maxWidth: 500) {
              src
            }
          }
        }
      }
    `)
    const popover = (content) => (
        <Popover id="popover-basic">
            <StyledPopOver>
                <Popover.Content as="p">{content}</Popover.Content>
            </StyledPopOver>
        </Popover>

    );

    const data = [
        {
            id: 1,
            icon: props.mobile.childImageSharp.fluid.src,
            alt: 'Music Composition',
            title: 'Music Composition',
            content: '1. Background score for Films, Web series and Games\n2. Songs & Jingles for Films, Web series, Ads and Indie projects',
            style: {
                marginTop: (typeof window !== 'undefined' && window.innerWidth < 500) ? undefined : '48px'
            }
        },
        {
            id: 2,
            icon: props.web.childImageSharp.fluid.src,
            alt: 'Music Production',
            title: 'Music Production',
            content: '1. Orchestral / Strings arrangement for songs, scores and concerts\n2. Music Programming for Films and Indie projects',
            style: {
                marginTop: (typeof window !== 'undefined' && window.innerWidth < 500) ? undefined : '48px'
            }
        },
        {
            id: 3,
            icon: props.email.childImageSharp.fluid.src,
            alt: 'Orchestration & Score Prep',
            title: 'Orchestration & Score Prep',
            content: '1. Sheet music preparation for Orchestral recordings\n2. Booking and recording of Orchestras & Musicians across the world',
            style: {}
        },
        {
            id: 4,
            icon: props.seo.childImageSharp.fluid.src,
            alt: 'Sound Design',
            title: 'Sound Design',
            content: '1. Sound design for Games, Documentaries',
            style: {
                marginTop: (typeof window !== 'undefined' && window.innerWidth < 500) ? undefined : '48px'
            }
        }
    ];

    const renderService = () => {
        return data.map(({ id, icon, alt, title, content, style }) => (
            <StyledCol key={id} md={3}>
                <AnimationContainer animation="fadeInLeft" delay={100}>
                    <OverlayTrigger trigger="click" placement="bottom" overlay={popover(content)} rootClose>
                        <ServiceElement>
                            <ServiceIcon>
                                <img src={icon} alt={alt} loading='lazy' />
                            </ServiceIcon>
                            <ServiceHeading>
                                {title}
                            </ServiceHeading>
                            <ServiceSeparator customStyle={style} />
                        </ServiceElement>
                    </OverlayTrigger>
                </AnimationContainer>
            </StyledCol>
        ))
    }

    return (
        <Section id="services">
            <ServiceContainer>
                <Container>
                    <AnimatedHeading text="My Services" />
                    <Row>
                        {renderService()}
                    </Row>
                </Container>
            </ServiceContainer>
            <GlobalStyles />
        </Section>
    )
}

export default Services;